<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {
    getToken,
} from "@/utils/api";
// import axios from 'axios'
import $ from 'jquery'
export default {
    name: "app",
    data() {
        return {};
    },
    methods: {},
    created() {
        window.vmm = this;
        const params = {
            grant_type: 'password',
            username: 'Aotain',
            password: 'aOtaIn20190627Ku3Pe84bnLuxc'
        }
        getToken(params).then(res=>{
            if(res.data.access_token){
              localStorage.setItem('skey',res.data.access_token)
            }
        })
    },
    mounted() {
      window.history.pushState(null, null, window.location.href);
    },
    beforeDestroy(){},
};
</script>
<style lang="less">
*{
  margin:0;
  padding: 0;
}
#app {
  font-family: "Microsoft YaHei";
  font-family: "Microsoft YaHei" ! important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

</style>
