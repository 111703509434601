import { 
    get, 
    post, 
    // del, 
    // put 
} from './http.js'
export function getToken(data,) {
    return post('/api/token', data,true,'x-www-form-urlencoded')
}

export function getMonitorPlanFindByBoxNo(openid,id) {
    return post('/api/wechat/MonitorPlanFindByBoxNo?OpenId='+openid+'&Boxnumber='+id,'',false)
}

export function getFindByNoToChat(openid,id) {
    return post('/api/wechat/FindByNoToChat?OpenId='+openid+'&MonitorplanNo='+id,'',false)
}
export function getBaiduMapTrack(data) {
    return post('/api/wechat/BaiduMapTrack', data,false)
}
export function getJSON(data) {
    return get('/json/'+data+'.json', data)
}

export function getMonitorPlanFindByBoxNoWithPCweb(db,id) {
    return post('/api/wechat/MonitorPlanFindByBoxNoWithPCweb?db='+db+'&Boxnumber='+id,'',false)
}
export function getBaiduMapTrackWithPCweb(data) {
    return post('/api/wechat/BaiduMapTrackWithPCweb', data,false)
}