import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    // mode: 'history',
    mode: "hash",
    routes: [
        {
            path: '/',
            name: 'index',
            components: {
                default: () => import('@/views/mini/index.vue'),
            }
        },
        {
            path: '/map',
            name: 'map',
            components: {
                default: () => import('@/views/web/map.vue'),
            }
        },
        // {
        //     path: '/GDmap/:openid/:id/:type',
        //     name: 'GDmap',
        //     components: {
        //         default: () => import('@/views/GDmap.vue'),
        //     }
        // },
        // {
        //     path: '/BDmap/:openid/:id/:type',
        //     name: 'BDmap',
        //     components: {
        //         default: () => import('@/views/BDmap.vue'),
        //     }
        // },
        {
            path: '*',//404
            name: '404',
            component: () => import('@/views/404.vue')
        },
    ]
})

// router.beforeEach((to, from, next) => {
//   console.log('to:',to)
//   console.log('from:',from)
//   console.log('next:',next)
//   const skey=sessionStorage.getItem('skey')
//   console.log('skey:',skey)
//   if(to.path=='/'){
//     next()
//   }else{
//     if(!sessionStorage.getItem('skey')){
//       router.push({name:'login'})
//     }
//     next()
//   }
// })
export default router