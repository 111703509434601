import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import axios from "axios";

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI)

import $ from 'jquery'
Vue.prototype.jquery = $ 

Vue.config.productionTip = false

// axios.defaults.timeout = 5000 // 请求超时
// axios.defaults.baseURL = '/api'// api 即上面 vue.config.js 中配置的地址

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
