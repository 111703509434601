import axios from 'axios'
var qs = require('qs');

export function get(url, data) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: data,
            headers: {
                'Authorization': localStorage.getItem('skey')
            }
        })
        .then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
            window.vmm.$message.warning("请求服务器错误！")
        })
    })
}
export function post(url, data, isQs,contentType) {
    return new Promise((resolve, reject) => {
        axios.post(url, isQs?qs.stringify(data):data, {
            headers:{
                'Content-Type': contentType?contentType:'application/json;charset=UTF-8',
                'Authorization': "bearer " + getSkey()
            }
        })
        .then(res => {
            resolve(res)
        }).catch(err => {
            console.log("请求服务器失败：",err)
            if(err.response.status==401){
                refreshSkey()
                return;
            }
            reject(err)
            window.vmm.$message.warning("请求服务器错误！")
        })
    })
}
export function put(url, data) {
    return new Promise((resolve, reject) => {
        axios.put(url, data, {
            headers: {
                'Authorization': localStorage.getItem('skey')
            }
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
            console.log("请求服务器失败：",err)
            window.vmm.$message.warning("请求服务器错误！")
        })
    })
}
export function del(url, data) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: data,
            headers: {
                'Authorization': localStorage.getItem('skey')
            }
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
            console.log("请求服务器失败：",err)
            window.vmm.$message.warning("请求服务器错误！")
        })
    })
}

const getSkey = () => {
    try {
        const skey = localStorage.getItem('skey')
        if (skey) {
            return skey;
        } else {
            return "";
        }
    } catch (error) {
        return "";
    }
}

const refreshSkey = () =>{
    const params = {
        grant_type: 'password',
        username: 'Aotain',
        password: 'aOtaIn20190627Ku3Pe84bnLuxc'
    }
    axios.post('https://wechat-applet.at-yun.com/api/token', qs.stringify(params), {
        headers:{
            'Content-Type': 'x-www-form-urlencoded',
        }
    })
    .then(res => {
        if(res.data.access_token){
            localStorage.setItem('skey',res.data.access_token)
            window.vmm.$router.go(0)
        }
    }).catch(err => {
        console.log("请求服务器失败：",err)
    })
}